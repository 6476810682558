















































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminSubs } from '@/store/admin/getters';
import { dispatchGetSubs, dispatchDeleteSub } from '@/store/admin/actions';

@Component
export default class AdminSubs extends Vue {
  public search = '';

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Price',
      sortable: true,
      value: 'price',
      align: 'left',
    },
    {
      text: 'Categories',
      sortable: false,
      value: 'categories',
      align: 'left',
    },
    {
      text: 'Hidden',
      value: 'hidden',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
    },
  ];
  get subs() {
    return readAdminSubs(this.$store);
  }

  public async deleteSub(subID) {
    await dispatchDeleteSub(this.$store, subID);
  }

  public async mounted() {
    await dispatchGetSubs(this.$store);
  }
}
